.cls-1,
.cls-2 {
  fill: none;
  stroke: #1d1d1b;
  stroke-width: 6.45px;
}
.cls-1 {
  stroke-miterlimit: 10;
}
.cls-2 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
/*# sourceMappingURL=src/oapps/commons/pictos/co2.css.map */